export const EVENTS = {
  WEB_Biz_Claim_Business: "WEB_Biz_Claim_Business",
  WEB_Biz_Claim_Business_Form: "WEB_Biz_Claim_Business_Form",
  WEB_Biz_Dashboard_Authenticated: "WEB_Biz_Dashboard_Authenticated",
  WEB_Biz_Open_Calendly_Link: "WEB_Biz_Open_Calendly_Link",
  WEB_Claim_Business_Non_Hospitality: "WEB_Claim_Business_Non_Hospitality",
  WEB_Location_Default_Video: "WEB_Location_Default_Video",
  WEB_Location_Empty_Product_Education: "WEB_Location_Empty_Product_Education",
  WEB_Location_Empty_Product_Education_Variant: "WEB_Location_Empty_Product_Education_Variant",
  WEB_Location_Profile: "WEB_Location_Profile",
  WEB_Video_Auto_Scroll: "WEB_Video_Auto_Scroll",
  WEB_Video_Scroll: "WEB_Video_Scroll"
};
