import React from "react";
import { useLocation } from "react-router-dom";
import { checkIsNonHospitalityBusiness, getClaimBusinessUrl } from "utils/business";
import useTrackEvent from "hooks/useTrackEvent";
import { EVENTS } from "utils/constants/events";
import ArrowRight from "images/white-arrow-back.svg"
import styles from "./ClaimBusinessBanner.module.css";

const ClaimBusinessBanner = ({ locationData }) => {
  const trackEvent = useTrackEvent();

  const isNonHospitality = checkIsNonHospitalityBusiness(locationData);

  const { pathname } = useLocation();
  const source = pathname === "/" ? "canonical" : pathname;

  return (
    <div className={styles.container}>
      <div className={styles.contentContainer}>
        <span className={styles.text}>
          Claim your businesses on Atmosfy today to connect with locals in your area
        </span>
        <a
          onClick={() => trackEvent(
            isNonHospitality ? EVENTS.WEB_Claim_Business_Non_Hospitality : EVENTS.WEB_Biz_Open_Calendly_Link, { source }
          )}
          className={styles.link}
          href={getClaimBusinessUrl(isNonHospitality, pathname, locationData)}
          target="_blank"
          rel="noreferrer"
        >
          <span className={styles.linkText}>Claim Business</span>
          <img src={ArrowRight} alt="Arrow Right" className={styles.icon}/>
        </a>
      </div>
    </div>
  )
}

export default ClaimBusinessBanner;
